import axios from "axios";
let baseURL = process.env.VUE_APP_API_URL;
export const state = {
  allPayments: [],
};

export const mutations = {
  SET_PAYMENTS(state, payment) {
    state.allPayments = payment;
  },
};
export const actions = {
  // get payments
  // eslint-disable-next-line no-unused-vars
  payments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(baseURL + "transactions" + payload.queryString, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          commit("SET_PAYMENTS", response.data.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  getPaymentDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(baseURL + "transactions?id=" + payload.transactionId, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  getTransactionByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(baseURL + "transactions?user_id=" + payload.userId, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
