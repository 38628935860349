import axios from "axios";
let baseURL = process.env.VUE_APP_API_URL

export const state = {
};

export const getters = {

};

export const mutations = {
};

export const actions = {

  // all stats
// eslint-disable-next-line no-unused-vars
getStats({commit}) {
  return new Promise((resolve, reject) => {
    axios.get(baseURL + "stats", {
      headers: {
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

getUserStats() {
  return new Promise((resolve, reject) => {
    axios.get(baseURL + "users/stat", {
      headers: {
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},
}
