import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'

import vco from "v-click-outside"
import VueOtp2 from 'vue-otp-2';
import OtpInput from "@bachdgvn/vue-otp-input";
// import moment from 'moment'
import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import VueEasyLightbox from 'vue-easy-lightbox'

Vue.use(VueEasyLightbox)

// import axios from "@/helpers/axios"

import "@/assets/scss/app.scss";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)
Vue.use(VueOtp2)
Vue.component("v-otp-input", OtpInput);

// Vue.filter('incomingDate', function(value) {
//   if (value) {
//     return moment(String(value)).format('DD MMMM YYYY hh:mm:ss a')
//   }
// })


// Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
