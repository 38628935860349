import axios from "../../helpers/axios";

// export const state = {
//   products: null
// };

// export const getters = {
//   getProducts: (state) => state.products,
//   getSingleProduct: (state) => (id) => {
//     return state.products.find(
//       (product) => product.id == id
//       );
//     },
// };

// export const mutations = {
//   SET_PRODUCTS(state, products) {
//     state.products = products
//   },
// };

export const actions = {
  // update profile
  // eslint-disable-next-line no-unused-vars
  updateProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("user/update", payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // create octamile profile
  // eslint-disable-next-line no-unused-vars
  createOtamileProfile({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("create-profile")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
