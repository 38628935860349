<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
};
</script>

<style>
.light-blue {
  background: #f9fbff !important;
}
.btn {
  height: 50px;
}

.form-control {
  height: 50px;
}

.authentication-bg {
  background: url("./assets/images/background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100vh;
}
.authentication-bg .bg-overlay {
   background: rgba(16, 40, 85, 2.85) !important;
   height: 100vh;
}

.vertical-menu, .navbar-brand-box {
  background: #102855 !important;
}

body {
  background: #f9fbff !important;
}

.card {
  box-shadow: none !important;
}
</style>