// import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/auth/login'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/pages/dashboard/index'),
        meta: { requiresAuth: true },
    },
   
    {
        path: '/logout',
        name: 'logout',
    },
    
    {
        path: '/organisation',
        name: 'organisation',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/organisation/index')
    },

    {
        path: '/organisation/edit/:id',
        name: 'edit-organisation',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/organisation/editOrganisation'),
    },

    {
        path: '/all-users',
        name: 'all-users',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/index'),
      
    },
    {
        path: '/view-user-details/:id',
        name: 'view-user-details',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/viewUserDetails')
    },

    {
        path: '/payment',
        name: 'payment',
        meta: { authRequired: true },
        component: () => import('../views/pages/payment/index')
    },
    {
        path: '/payment/:id',
        name: 'transaction-details',
        meta: { authRequired: true },
        component: () => import('../views/pages/payment/viewPayment')
    },
    {
        path: '/insurance',
        name: 'insurance',
        meta: { authRequired: true },
        component: () => import('../views/pages/insurance/index')
    },
]
