import axios from "../../helpers/axios";

export const state = {
  organisationList: []
};


export const actions = {

  // get organisation
  // eslint-disable-next-line no-unused-vars
  getAllOrganisations({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("organizations")
        .then((response) => resolve(response))
        .catch(error => reject(error));
    })
  },

  // get organisation by user
  // eslint-disable-next-line no-unused-vars
  filterOrganisationByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('organizations?user_id=' + payload.payload.user_id)
        .then((response) => resolve(response))
        .catch(error => reject(error))
    })
  },
   // get organisation by user
  // eslint-disable-next-line no-unused-vars
  organizationDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('organizations?org_id=' + payload.payload.org_id)
        .then((response) => resolve(response))
        .catch(error => reject(error))
    })
  }
}
