import axios from "axios";
let baseURL = process.env.VUE_APP_API_URL;

export const state = {
  users: [],
};

export const getters = {};

export const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
};

export const actions = {
  // all users
  // eslint-disable-next-line no-unused-vars
  getAllUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(baseURL + "users" + payload.queryString, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
          commit("SET_USERS", response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  getSingleUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(baseURL + "user/" + payload.payload, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  approveUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(baseURL + `activate/${payload.payload}`, {}, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  rejectUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(baseURL + "deactivate/" + payload.payload.id, payload.payload.body, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
