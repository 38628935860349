import axios from "axios";
let baseURL = process.env.VUE_APP_API_URL;
export const state = {

};

export const mutations = {
  SET_PAYMENTS(state, payment) {
    state.allPayments = payment;
  },
};
export const actions = {
  // get payments
  // eslint-disable-next-line no-unused-vars
  allInsurance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(baseURL + "insurance/all" + payload.queryString, {
          headers: {
            Authorization: `Bearer ${this.state.auth.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
