import axios from "../../helpers/axios";

export const actions = {
  // upload document
  // eslint-disable-next-line no-unused-vars
  uploadDocument({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("upload", payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  checkUpload({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("user/check-upload", payload.payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
