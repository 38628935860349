import axios from 'axios'
// import { state } from '../state/modules/auth'

// import store from '../state/store'

// let url = process.env.VUE_APP_API_URL
// let AccessKey = process.env.VUE_APP_ACCESS_KEY

// const userToken = localStorage.getItem('token');  

// axios.defaults.baseURL = baseURL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// axios.defaults.headers.common['access-key'] = `${AccessKey}`;
// axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
const token = localStorage.getItem('token'); 
const options = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Accept: "application/json,text/plain,*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
let instance = axios.create(options)

instance.interceptors.request.use(request => {
    const token = localStorage.getItem('token'); 
    // console.log("token", userToken)
    if (token) {
        request.headers.common['Accept'] = 'application/json';
        request.headers.common['Content-Type'] = 'application/json';
        request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // request.headers.common['access-key'] = AccessKey;
    }
    return request;    
}) 

// const setAuthorizationHeader = token => {
//     if (token) {
//       instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//     } else {
//       delete instance.defaults.headers.common['Authorization'];
//     }
//   };

// axios.interceptors.response.use(
//     response => {
//       // Check if response data exists
//       return response;
//     },
//     error => {
//       // Handle response errors
//       if (error.response && error.response.data) {
//         // Handle response error data, if available
//       }
//       return Promise.reject(error);
//     }
//   );


export default instance;

// import { instance } from './instanceHandler';

// // Response interceptor
// instance.interceptors.response.use(
//   response => {
//     // Modify the response data here, such as parsing or transforming it
//     // You can also perform logging or other tasks after the response is received
//     return response;
//   },
//   error => {
//     if (error.response && error.response.status === 401) {
//       // Handle authentication error, such as redirecting to the login page
//       // You can also clear the authentication token or perform other necessary actions
//     }
//     return Promise.reject(error);
//   }
// );

// export default instance;
