import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL

export const state = {
  
  token: null,
  // userDetails: null
};

export const mutations = {

  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem("token", state.token);
  },
  
  CLEAR_USER_DATA(state) {
    state.token = null;
    state.currentUser = null;
    state.userDetails = null;
    localStorage.clear();
    window.location.href = "/";
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn: (state) => !!state.currentUser,
  getCurrentUser: (state) => state.currentUser,
};

export const actions = {
  // Logs in the current user.
// eslint-disable-next-line no-unused-vars
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(baseURL + "login", payload)
        .then((response) => {
          const token = response.data.data.token;
          commit('SET_TOKEN', token);
          resolve(response);
        })
        .catch((error) => reject(error));
    });  
  },

  //   logout user
  logout({ commit }) {
    commit("CLEAR_USER_DATA");
  }

};
